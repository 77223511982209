import React, { useState, useEffect } from 'react';
import RichTable from '../components/richTable/RichTable.jsx'
import tableHelpers from '../helpers/TableHelpers.js'
import DailyBalanceChart from '../plan/DailyBalanceChart.js'
import PlanScorecard from '../plan/PlanScorecard.js';
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker"
import forceLogout from '../helpers/forceLogout.js'


function AddAccount(props) {
    const [subscriptions, setSubscriptions] = useState([]);
    const [currentBalance, setCurrentBalance] = useState('')
    const [planId, setPlanId] = useState(0)
    const [name, setName] = useState('')
    const [backtestResults, setBacktestResults] = useState({})
    const [chartData, setChartData] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [updates, setUpdates] = useState(0)

    useEffect(() => {
        async function getSubscriptions() {
            const response = await fetch(`/api/subscriptions?userId=${props.userId}`)
            if (response.ok) {
                const text = await response.json()
                setSubscriptions(text)
            } else {
                console.error(`Failed to load subscriptions for user ${props.userId} - HTTP ${response.status}`)
                forceLogout()
                return
            }
        }
        getSubscriptions()

        async function getAccounts() {
            const response = await fetch(`/api/accounts?userId=${props.userId}`)
            if (response.ok) {
                const text = await response.json()
                setAccounts(text)
            } else {
                console.error(`Failed to load accounts for user ${props.userId} - HTTP ${response.status}`)
                forceLogout()
                return
            }
        }
        getAccounts()

    }, [props.userId, updates])

    const plans = getPlans()


    async function handleSubmit() {
        console.log(`Adding account for user ${props.userId} with planId ${planId}, balance ${currentBalance}, name ${name}`)
        if (parseInt(planId) > 0 && parseFloat(currentBalance) > 0 && name.length) {
            const url = "/api/addAccount?userId=" + props.userId + "&planId=" + planId + "&balance=" + currentBalance + "&name=" + name;
            console.log(url);

            const response = await fetch(url)
            await response.json()
            setUpdates(updates + 1);
            setName('');
            setCurrentBalance('');
            setPlanId(undefined)
            if (props.onAccountsUpdated)
                props.onAccountsUpdated();
        }
    }

    function getPlans() {
        return subscriptions.map(plan => {
            return {
                name: plan.name,
                description: plan.description,
                planId: plan.planId,
                selected: planId === plan.planId,
                annual_profit_percentage: plan.annual_profit_percentage,
                weekly_trade_count: plan.annual_trade_count / 52,
                max_drawdown_percentage: plan.max_drawdown_percentage,
                average_weekly_drawdown: plan.average_weekly_drawdown,
                best_year: plan.best_year,
                worst_year: plan.worst_year,
                backtest_years: plan.backtest_years
            };
        })
    }

    async function onPlanSelected(planId) {
        console.log("onPlanSelected", planId);
        setPlanId(planId);

        const url = `/api/lastBacktestResults?planId=` + planId + "&liveTradesOnly=0";
        let text = await (await fetch(url)).json();

        const tempChartData = [];//[["Date", "Account Value", "S&P 500"]]
        text.dailyResults.forEach(day => tempChartData.push([new Date(day.date), day.total, day.baseline]))
        setChartData(tempChartData)
        setBacktestResults(text);
    }

    function selectedFormatter(cell, row) {
        return (
            <input type='checkbox' checked={row.selected} onClick={() => onPlanSelected(row.planId)} />
        );
    }

    function getAccounts() {
        return accounts.map(a => {
            return {
                name: a.name,
                plan_name: a.plan_name,
                current_balance: a.current_balance,
                create_date: a.create_date,
                idaccounts: a.idaccounts
            }
        })
    }

    async function onDeleteAccount(accountId) {
        console.log("onDeleteAccount", accountId);
        const url = "/api/accountDelete?userId=" + props.userId + "&accountId=" + accountId;
        await fetch(url, { method: "post" });
        setUpdates(updates + 1);
        if (props.onAccountsUpdated)
            props.onAccountsUpdated();
    }

    function deleteLinkFormatter(cell, row) {

        let accountId = row.idaccounts;
        return (
            <Link className="bg-transparent" onClick={() => onDeleteAccount(accountId)}>Delete</Link>
        )
    }

    async function updateStartDate(accountId, date) {
        console.log("Updating start date", accountId, date);
        const url = "/api/accountStartDate?accountId=" + accountId + "&startDate=" + date;
        console.log(url);
        await fetch(url, { method: "post" });
        setUpdates(updates + 1);
        if (props.onAccountsUpdated)
            props.onAccountsUpdated();
    }

    function startDateFormatter(cell, row) {

        let d = new Date(row.create_date);
        return (
            <DatePicker className="bg-transparent" selected={d} onChange={(date) => updateStartDate(row.idaccounts, date)} />
        )
    }

    function getRowClassName(row) {
        return "analytics_hidden"
    }

    return (
        <div>

            <h3>
                Current Accounts
            </h3>
            <RichTable className="table-striped table-hover table-condensed" rowClassName={getRowClassName} data={getAccounts()} mappers={[
                { title: 'Account', field: 'name' },
                { title: 'Plan', field: 'plan_name' },
                { title: 'Balance', field: 'current_balance', formatter: tableHelpers.moneyFormatterNoCents },
                { title: 'Start Date', field: 'create_date', formatter: startDateFormatter },
                { title: 'Manage', field: 'idaccounts', formatter: deleteLinkFormatter }
            ]} />

            <hr></hr>

            <h3>
                Add Account
            </h3>
            Adding an account will make it easier to manage multiple accounts. It will save the plan
            and current balance associated with each account and allow you to quickly switch between them via the
            dropdown menu at the bottom of the site.
            <table width="100%">
                <tbody>
                    <tr>
                        <td width="125" valign='top'>

                            <br></br>
                            Current Balance: <input type="text" size="10" placeholder="10000" value={currentBalance} onChange={(e) => setCurrentBalance(e.target.value)}></input>

                            <br></br>
                            <br></br>
                            Account Nickname: <input type="text" size="20" placeholder="Nickname" value={name} onChange={(e) => setName(e.target.value)}></input>

                            <br></br>
                            <br></br>
                            Select a plan:
                            <RichTable className="table-striped table-hover table-condensed" headless data={plans} mappers={[
                                { title: 'Selected', field: 'selected', formatter: selectedFormatter },
                                { title: 'Plan', field: 'name' }
                            ]} />

                            <br></br>
                            * Note - your account value will be stored
                            <br></br>
                            <button class="btn btn-secondary" onClick={(e) => handleSubmit()}>Add Account</button>

                        </td>
                        <td>
                            {
                                backtestResults && backtestResults.periodSummaries ?
                                    <div width="100%">
                                        <DailyBalanceChart defaultToYtd={false} defaultToSyncValues={false} backtestResults={backtestResults} chartData={chartData} hideAllocationChart={true} hideChartSummary={true}></DailyBalanceChart>
                                        <div width="75%">
                                            <center>
                                                <PlanScorecard backtestResults={backtestResults} />
                                            </center>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </td>
                    </tr>
                </tbody>
            </table>

        </div >);
}

export default AddAccount;