import React, { useState, useEffect, useRef } from 'react';
import TableHelpers from '../helpers/TableHelpers';
import { DateTime, Settings } from "luxon";
import Tooltip from '../components/Tooltip';
import RichTable from '../components/richTable/RichTable'
import { createDefaultSorter } from '../components/richTable/sorters'
import { Link } from 'react-router-dom'
import LinearProgress from '@mui/material/LinearProgress';

function TradeInstructions(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [tradeDate, setTradeDate] = useState(null);
    const [trades, setTrades] = useState([]);
    const [closedTrades, setClosedTrades] = useState({})
    const [remainingTime, setRemainingTime] = useState(null)
    const [previewTrades, setPreviewTrades] = useState(null);
    const [marketHours, setMarketHours] = useState(null);
    const timerRef = useRef(null);

    useEffect(() => {
        (async function () {

            const loadTrades = async () => {
                console.log("Loading trades");
                var sendDate = (new Date()).getTime();
                setIsLoading(true);
                const url = `/api/newTrades?planId=` + props.planId;
                const trades = await (await fetch(url)).json();
                var receiveDate = (new Date()).getTime();
                var responseTimeMs = receiveDate - sendDate;
                console.log("newTrades took " + responseTimeMs + " ms");
                setTrades(trades.instructions);
                setTradeDate(new Date(trades.date));
                setClosedTrades(trades.closedTrades);
                setIsLoading(false);
            }

            const loadMarketHours = async () => {
                const url = `/api/marketHours`;
                const hours = await (await fetch(url)).json();
                return hours;
            }

            const updateTimer = async (marketHours) => {
                Settings.defaultZone = "America/New_York";
                let now = DateTime.now();
                let marketClose = DateTime.now();
                if (marketHours && marketHours.hour) {
                    marketClose = marketClose.set({ hour: marketHours.hour, minute: marketHours.minute + 4, second: 0, millisecond: 0 });
                } else {
                    marketClose = marketClose.set({ hour: 16, minute: 4, second: 0, millisecond: 0 });
                }
                let i = marketClose.diff(now);

                setRemainingTime(i);
                return i.milliseconds;
            }

            let hours = await loadMarketHours();
            setMarketHours(hours);

            loadTrades();

            const msUntilMarketClose = await updateTimer();

            if (timerRef.current) clearInterval(timerRef.current);
            console.log("Time until trades available", msUntilMarketClose);
            if (msUntilMarketClose > 0) {
                const id = setInterval(async (hours) => {
                    const ms = await updateTimer(hours);
                    if (ms <= 0) {
                        console.log("Reloading trades because timer expired", ms);
                        loadTrades();
                        clearInterval(timerRef.current);
                    }
                }, 1000, hours);
                timerRef.current = id;
            }
        })();
    }, [props.planId]);

    function getInstructionText(instruction) {
        if (instruction.action === "Reduce") {
            let shares = Math.floor(props.accountValue / instruction.price * (instruction.amount / 100));
            return instruction.action + " " + instruction.symbol + " by " + instruction.amount + "% (" + shares + " shares)";
        } else if (instruction.action === "Add") {
            let shares = Math.floor(props.accountValue / instruction.price * (instruction.amount / 100));
            return instruction.action + " " + instruction.amount + "% to " + instruction.symbol + " (" + shares + " shares)";
        } else if (instruction.action === "Close") {
            return "Sell all shares of " + instruction.symbol;
        } else {
            return "Unknown action";
        };
    }

    function renderProfit() {
        if (closedTrades && closedTrades.netProfit) {
            let dollarProfit = TableHelpers.moneyFormatterNoCents(props.accountValue * closedTrades.netProfit / 100);
            return (<div>
                * There were <Link to="/trades/completed">completed trades</Link> that resulted in a net profit of {closedTrades.netProfit.toFixed(2)}% (roughly {dollarProfit})
            </div>
            )
        } else
            return null;
    }

    async function loadTradePreview() {
        console.log("loadTradePreview")
        setPreviewTrades(null);
        const url = `/api/strategyAlertTradePreview?planId=` + props.planId;
        const trades = await (await fetch(url)).json();
        console.log(trades);
        setPreviewTrades(trades.sort((a, b) => a.symbol < b.symbol ? -1 : 1));
    }

    function getTradePreview() {
        if (previewTrades === null) {
            return <div>Checking...</div>
        } else if (previewTrades.length === 0) {
            return <div>No trades as of right now</div>
        } else {
            return (
                <div>
                    Things might change so don't take action yet...<br></br>
                    <ul>
                        {
                            previewTrades.map(t =>
                                <li>{t.action.toUpperCase()} {t.percentage}% of <Link to={"/stock/" + t.symbol}>{t.symbol}</Link>{t.timeframe === 1 ? " on Friday" : ""} by <Link to={"/algo/" + t.algoId}>{t.display_name}</Link>{t.profitPercentage ? (t.profitPercentage > 0 ? " for profit" : " for loss") : null}</li>
                            )
                        }
                    </ul>
                </div>
            )
        }
    }

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    function renderNoTrades() {
        let now = DateTime.now();

        if (now.isWeekend || (marketHours && marketHours.isClosed)) {
            return <>The market is closed today</>
        } else {
            const hoursForCountdown = 9;
            if (remainingTime && remainingTime.milliseconds > 0 && remainingTime.milliseconds < (1000 * 60 * 60 * hoursForCountdown)) {
                let hours = Math.floor(remainingTime.milliseconds / 1000 / 60 / 60)
                let minutes = Math.floor((remainingTime.milliseconds / 1000 / 60) % 60)
                let seconds = Math.floor(((remainingTime.milliseconds / 1000) % 60));
                let remaining = hours + ":" +
                    zeroPad(minutes, 2) + ":" +
                    zeroPad(seconds, 2);

                const disablePeekMinutes = 7;
                return (
                    <>Coming in {remaining}... <br></br>
                        {remainingTime && remainingTime.milliseconds > 1000 * 60 * disablePeekMinutes ?
                            <Tooltip width={'500px'} onOpen={() => loadTradePreview()} textClass="tooltip-link smaller-text" text="Take a sneak peek 🔍" position={props.popupLocation ? props.popupLocation : "right center"}>
                                {getTradePreview()}
                            </Tooltip>
                            : null
                        }

                    </>
                )
            } else {
                return <>No trades</>
            }
        }

    }

    return (

        <div>
            {!isLoading ?
                <>
                    {props.hideHeader ? null :
                        <h4>
                            Trades for {tradeDate ? (tradeDate.getUTCMonth() + 1) + "/" + tradeDate.getUTCDate() + "/" + (tradeDate.getUTCFullYear() - 2000) : "Unknown"}
                        </h4>
                    }
                    {trades.length ?
                        <>
                            <RichTable headless={true} data={trades} className="table-condensed" mappers={[
                                { title: 'Action', field: 'text', isDefaultSort: true, sorters: createDefaultSorter('symbol'), formatter: (_, r) => getInstructionText(r) },
                            ]} />

                            {props.hideProfit ? null : renderProfit()}
                        </>
                        :
                        <>
                            <br></br>
                            {renderNoTrades()}
                        </>
                    }
                </>
                :
                <LinearProgress />
            }
        </div >
    );
}

export default TradeInstructions;