import React from 'react';
import { Link } from 'react-router-dom'
import TradeInstructions from './TradeInstructions.js'

function NewTrades(props) {

    return (

        <center>
            <div>
                <TradeInstructions planId={props.planId} accountValue={props.accountValue} />
                <br></br>
                Go to <Link id="link" className="bg-transparent" to={"/recentUpdates"}>Trade History</Link> to see all trade details.
                <br></br>
                Go to <Link id="link" className="bg-transparent" to={"/plan"}>Positions</Link> to see resulting positions.
            </div>

        </center>
    );
}

export default NewTrades;