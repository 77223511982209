import React, { useState, useEffect } from 'react';
import PlanUpdates from './PlanUpdates.js';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ManageAlgos from './ManageAlgos.js'
import { useParams } from 'react-router-dom';

function UpdatePlan(props) {

    let { activeTab } = useParams();
    const [tab, setTab] = useState(activeTab ? activeTab : 'update');

    useEffect(() => {
        (async function () {
        })();
    }, []);

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    return (
        <div>
            <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={tab}
                        onChange={handleChangeTab}
                        textColor="secondary"
                        indicatorColor="secondary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab value="update" label="Update Plan" />
                        <Tab value="changes" label="Change History" />
                    </Tabs>
                </Box>
                <br></br>

                {tab === "update" ?
                    <>
                        <ManageAlgos onAlgoSelected={props.onAlgoSelected} onAlertSelected={props.onAlertSelected} planId={props.planId} userId={props.userId} role={props.role} mobileView={props.mobileView} />
                    </>
                    : null}
                {tab === "changes" ?
                    <>
                        <PlanUpdates onAlertSelected={props.onAlertSelected} planId={props.planId} onAlgoSelected={props.onAlgoSelected} />
                    </>
                    : null}
            </>
        </div>
    );
}

export default UpdatePlan;