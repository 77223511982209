import React, { useState, useEffect } from 'react';
import RichTable from '../components/richTable/RichTable.jsx'
import tableHelpers from '../helpers/TableHelpers.js'
import backtestResultsHelpers from '../helpers/BacktestResultsHelpers.js'
import TradeInstructions from '../trades/TradeInstructions.js';
import { Link } from 'react-router-dom'

function AccountSummaryCard(props) {

    const [isUpdatingAccountValue, setIsUpdatingAcccountValue] = useState(false);
    const [accountValue, setAccountValue] = useState(props.accountValue);

    useEffect(() => {
        setAccountValue(props.accountValue);
    }, [props.accountValue]);

    function getAccountInfo() {

        let netProfit = backtestResultsHelpers.getReturnSince(props.performanceResults, new Date(props.startDate), false);

        return [{
            name: props.accountName,
            planName: props.planName,
            accountValue: props.accountValue,
            todaysChange: props.todaysChange,
            netProfit: netProfit,
            todaysTrades: props.todaysTrades
        }];
    }

    function todaysTradesFormatter(cell, row) {
        return <TradeInstructions hideHeader={true} hideProfit={true} planId={props.planId} accountValue={props.accountValue} trades={props.todaysTrades} popupLocation="left center" />
    }

    async function onUpdateAccountValue() {

        console.log("onUpdateAccountValue")
        const url = "/api/accountValue?userId=" + props.userId + "&accountId=" + props.accountId + "&balance=" + accountValue;
        await fetch(url, { method: "post" })
        setIsUpdatingAcccountValue(false);

        if (props.onAccountValueUpdated)
            props.onAccountValueUpdated(props.accountId, accountValue);
    }

    function accountValueFormatter(cell, row) {
        if (isUpdatingAccountValue) {
            return (
                <>
                    <input type="text" defaultValue={props.accountValue} onChange={(e) => setAccountValue(e.target.value)} /><Link onClick={() => onUpdateAccountValue()}>Save</Link>
                </>
            )
        } else {
            return (
                <>
                    {tableHelpers.moneyFormatterNoCents(cell, row)}&nbsp;<Link className={"small-text"} onClick={() => setIsUpdatingAcccountValue(true)}>(Update)</Link>
                </>
            );
        }
    }

    function getRowClassName(row) {
        return "analytics_hidden"
    }

    return (
        <div>

            <h5>
                {props.accountName + " (" + props.planName + ")"}

                <RichTable data={getAccountInfo()} className="table-condensed" rowClassName={getRowClassName} mappers={[
                    { title: 'Account Value', field: 'accountValue', formatter: accountValueFormatter, width: '25%' },
                    { title: `Total P/L`, field: 'netProfit', formatter: tableHelpers.percentChangeFormatter, width: '25%' },
                    { title: `Today's Change`, field: 'todaysChange', formatter: tableHelpers.percentChangeFormatter, width: '25%' },
                    { title: `Today's Trades`, field: 'todaysTrades', formatter: todaysTradesFormatter, width: '25%' },
                ]} />
            </h5>
        </div >);
}

export default AccountSummaryCard;