import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import tableHelpers from '../helpers/TableHelpers.js'
import RichTable from '../components/richTable/RichTable.jsx'
import AlgoQuickStats from '../components/AlgoQuickStats.js'
import { createDateSorter } from '../components/richTable/sorters.js'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Tooltip from '../components/Tooltip.js'
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function BonusTrades(props) {
    const [data, setData] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [tradeShares, setTradeShares] = React.useState(0);
    const [tradeId, setTradeId] = React.useState(null);
    const [userTrades, setUserTrades] = useState([])
    const [updates, setUpdates] = useState(0);

    useEffect(() => {
        (async function () {
            let text = await (await fetch(`/api/allocationChanges/?planId=108`)).json();
            setData(text);

            let trades = await (await fetch(`/api/userTrades/?userId=` + props.userId)).json();
            setUserTrades(trades);
        })();
    }, [props.planId, props.userId, updates]);

    async function saveTrade() {

        let url = `/api/userTrade?userId=` + props.userId;
        url += "&planTradeId=" + tradeId
        url += "&shares=" + tradeShares;

        console.log(url);

        await (await fetch(url, { method: 'POST' })).json();

        setUpdates(updates + 1);
        handleCloseModal();
        handleCloseActionMenu();
    }

    async function handleForgetTrade() {
        let url = `/api/userTrade?userId=` + props.userId;
        url += "&planTradeId=" + tradeId
        url += "&shares=0";

        await (await fetch(url, { method: 'POST' })).json();

        setUpdates(updates + 1);
        handleCloseModal();
        handleCloseActionMenu();
    }

    function actionFormatter(cell, row) {

        let action = "";

        if (row.didTakeTrade)
            action += "🚨 "

        if (cell === 'buy') {
            if (row.didTakeTrade)
                action += "Bought";
            else {
                action += "Buy";
            }
        } else {
            if (row.didTakeTrade)
                action += "Sold";
            else {
                action += "Sell";
            }
        }

        if (row.didTakeTrade) {
            action += " " + tableHelpers.intFormatter(row.shares);
        }

        action += " " + row.trade_symbol

        return action;
    }

    function profitFormatter(cell, row) {

        let result = "";

        if (row.action === 'sell')
            result = tableHelpers.percentChangeFormatter(cell, row);
        else if (row.action === 'buy' && row.profitPercentage) {
            result = tableHelpers.percentChangeFormatter(row.profitPercentage, row);
        } else {
            result = tableHelpers.percentChangeFormatter(row.percentChangeSince, row);
        }

        result += (row.action === 'buy' && row.profitPercentage === undefined ? " (Active)" : " (Completed)");

        return result;
    }

    function getChanges() {
        let results = data.map(change => {
            let userTrade = userTrades.find(t => t.planTradeId === change.tradeId)
            let diff = (change.quote - change.close) / change.close * 100;
            return {
                date: change.date,
                trade_symbol: change.trade_symbol,
                display_name: change.display_name,
                tradeId: change.tradeId,
                algoId: change.algoId,
                action: change.action,
                close: change.close,
                algo_type: change.algo_type,
                profitPercentage: change.profitPercentage,
                percentChangeSince: diff,
                winning_percentage: change.winning_percentage,
                avg_profit: change.avg_profit,
                avg_win: change.avg_win,
                avg_loss: change.avg_loss,
                avg_duration: change.avg_duration,
                allocation: change.percentage,
                didTakeTrade: userTrade !== undefined && userTrade.quantity > 0,
                shares: userTrade !== undefined ? userTrade.quantity : 0,
                quote: change.quote,
                robustness_overall: change.robustness_overall
            }
        })

        // only display:
        // active trades
        // closed trades
        if (props.showAll)
            return results;
        else
            return results.filter(t => (t.action === 'buy' && t.profitPercentage === undefined) ||
                t.didTakeTrade);
    }

    function algoLinkFormatter(cell, row) {
        return (
            <>
                <Link id="link" className="bg-transparent" to={`/stock/${row.trade_symbol}`}>{row.trade_symbol}</Link>
                &nbsp;<Link className="bg-transparent" to={"/algo/" + row.algoId}>{cell}</Link>
                &nbsp;&nbsp;<Tooltip textClass={"tooltip-link"} text={"🔍"} width={225} borderWidth={"0px"} position="right center" >
                    <center>
                        <AlgoQuickStats winningPercentage={row.winning_percentage}
                            avgProfit={row.avg_profit}
                            avgWin={row.avg_win}
                            avgLoss={row.avg_loss}
                            avgDuration={row.avg_duration}
                            robustness={row.robustness_overall}
                            algoType={row.algo_type.toUpperCase()}
                        />
                    </center>
                </Tooltip >
            </>
        );
    }

    const actionsMenuOpen = Boolean(anchorEl);
    const handleOpenActionMenu = (event, row, tradeId) => {
        console.log("Opening menu for trade", tradeId);
        setTradeId(tradeId);
        setAnchorEl(event.currentTarget);
    };
    const handleCloseActionMenu = () => {
        setAnchorEl(null);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    }
    const handleOpenModal = () => {
        setModalOpen(true);
    }

    function actionsFormatter(cell, row) {
        if (row.profitPercentage === undefined || row.didTakeTrade)
            return (
                <Button
                    id="basic-button"
                    aria-controls={actionsMenuOpen ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={actionsMenuOpen ? 'true' : undefined}
                    onClick={(e) => handleOpenActionMenu(e, row, row.tradeId)}
                    style={{ padding: 0, margin: 0 }}
                >
                    🔻Actions
                </Button>
            );
        else
            return null;
    }

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    let selectedTrade = data.find(change => change.tradeId === tradeId);
    let exampleShares = selectedTrade && props.accountValue && selectedTrade.quote ? props.accountValue * .01 / selectedTrade.quote : 0

    return (
        <>
            <RichTable pageSize={10} data={getChanges()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Date', field: 'date', sorters: createDateSorter('date'), formatter: tableHelpers.dateFormmatter },
                { title: 'Algo', field: 'display_name', formatter: algoLinkFormatter },
                { title: 'Action', field: 'action', formatter: actionFormatter, sorters: true },
                { title: 'P/L', field: 'profitPercentage', formatter: profitFormatter, hidden: props.mobileView },
                { title: '', field: 'algoId', formatter: actionsFormatter },
            ]} />

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={actionsMenuOpen}
                onClose={handleCloseActionMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleOpenModal}>Take Trade</MenuItem>
                <MenuItem onClick={handleForgetTrade}>Undo Taken Trade</MenuItem>
            </Menu>

            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={modalOpen}
                onClose={handleCloseModal}
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        How many shares?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <input type="text" onChange={(e) => setTradeShares(e.target.value)} />
                        <br></br>
                        1% of account at {selectedTrade ? tableHelpers.moneyFormatter(selectedTrade.quote, null) : 0} = {tableHelpers.intFormatter(exampleShares)} shares
                        <br></br>
                        <br></br>
                        Note: these trades won't show up in your plan. You can only find them and their sell signals here in Bonus Trades.
                        <br></br>
                        <br></br>
                        <button class="btn btn-primary" onClick={saveTrade} type="button">Submit</button>
                    </Typography>
                </Box>
            </Modal>

        </>
    );
}

export default BonusTrades;