import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import PositionsTable from './positions/PositionTable.js';
import PositionsChart from './positions/PositionsChart.js';
import DailyBalanceChart from './plan/DailyBalanceChart.js'
import TradeInstructions from './trades/TradeInstructions.js'
import RecentReturns from './plan/RecentReturns.js'
import BonusTrades from './trades/BonusTrades.js'
import StickySettings from './helpers/StickySettings.js';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

function Overview(props) {

    const [positions, setPositions] = useState([]);
    const [previousPositions, setPreviousPositions] = useState([]);
    const [performanceResults, setPerformanceResults] = useState({});
    const [chartData, setChartData] = useState([]);
    const [proTip, setProTip] = useState(null);
    const timerRef = useRef(null);
    const [showProTips, setShowProTips] = useState(StickySettings.getBool("showProTips", true));

    useEffect(() => {
        (async function () {

            const loadPerformanceResults = async () => {
                console.log("Loading performance results...");
                setChartData([])
                setPerformanceResults({})
                const url = `/api/lastBacktestResults?planId=` + props.planId + "&liveTradesOnly=1";
                let text = await (await fetch(url)).json();

                const tempChartData = [];
                text.dailyResults.forEach(day => tempChartData.push([new Date(day.date), day.total, day.baseline]))
                setChartData(tempChartData)
                setPerformanceResults(text);
            }

            const loadPositions = async () => {
                console.log("Loading positions...");
                setPositions([])
                const posRes = await fetch(`/api/positions?planId=` + props.planId)
                const pos = await posRes.json();
                setPositions(pos);
            }

            const loadPreviousDayPositions = async () => {
                console.log("Loading prev positions...");
                setPreviousPositions([])
                const pos = await (await fetch(`/api/previousPositions?planId=` + props.planId)).json();
                console.log("previous positions", pos);
                setPreviousPositions(pos);
            }

            function getProTip() {
                const proTips = [
                    <><Link className="bg-transparent" to={"https://discord.gg/fWtCEQjVWe"} >Join our Discord server</Link> to chat with other traders!</>,
                    <>Have friends that you want to share this with? They can <Link target="_blank" className="bg-transparent" to={"https://twitter.com/AlgoStockTrades"} >follow on Twitter</Link>. Trades from the "Public Plan" are posted there daily.</>,
                    <>Change the color of down bars on candlestick charts on your preferred charting platform. Red can raise your heart rate and cause unnecessary stress.</>,
                    <>Drawdowns are unavoidable in swing trading. If drawdowns cause you too much anxiety, you can either lower the amount of money that you're trading or choose a plan with smaller drawdowns.</>,
                    <>Reconcile your account positions against the plan positions at least once a week. It's easy to make a small mistake when placing an order which could have a big impact.</>,
                    <>Make sure you don't have dividend reinvestments turned on in your brokerage account. The plan will automatically put that cash to work for you.</>,
                    <>Trend following strategies almost always have to give back some of their gains before selling, otherwise they're probably selling too early.</>,
                    <>Your brokerage won't match completed trades to their correct opening trade. So their realized gains won't match up with the ones you see here. "Closed Trades" is really the source of truth.</>,
                    <>It's almost impossible to predict when the best time is to buy-in to a plan or deposit more funds. Just make a decision and don't look back.</>,
                    <>Always check the Order Status page on your brokerage account after placing your trades to make sure they executed fully.</>,
                    <>If you want to put your extra cash to work, consider holding money market funds with some percentage of your cash. However, you'll need to manage them on your own and make sure you have the cash freed up before purchasing stocks.</>,
                    <>Always update your current account value at the bottom of the screen before determining how many shares to buy/sell.</>,
                    <>If you're managing multiple accounts, the account dropdown at the bottom will make that much easier.</>,
                    <>Do a full refresh of this site in your browser every once in a while to ensure you have the latest version.</>,
                    <>You can set your start date on the Manage Accounts page found at the bottom. That will allow you to see your P/L since your start date on the Overview page.</>,
                    <>If you're trading in a taxable account and have realized gains at the end of the year, set aside cash that can be used to pay your taxes without risking losing it in the market</>,
                    <>Below the countdown timer is a link that will tell you what the algorithms are thinking at that point in time. Keep in mind, the only time it matters what they're thinking is at market close, so a lot can change! (For entertainment purposes only)</>,
                    <>Choose a plan based on how much money you're willing to lose, not how much you want to make. You won't stick with it through drawdowns if it doesn't match your risk tolerance.</>,
                    <>If you're consistently stressed out by the volatility of your account, you should consider scaling down the amount of money that you're trading or switching to a more conservative plan.</>,
                ];

                return proTips[Math.floor((Math.random() * proTips.length))]
            }


            setProTip(getProTip());
            loadPerformanceResults();
            await loadPositions();
            await loadPreviousDayPositions();

            if (timerRef.current) clearInterval(timerRef.current);
            const id = setInterval(async () => {
                loadPositions();
                loadPreviousDayPositions();
            }, 60000);
            timerRef.current = id;
        })();
    }, [props.planId]);

    async function reloadQuotes() {
        const pos = await (await fetch(`/api/positions?planId=` + props.planId)).json();
        setPositions(pos);

        const prev = await (await fetch(`/api/previousPositions?planId=` + props.planId)).json();
        setPreviousPositions(prev);
    }

    function handleRefreshClicked() {
        reloadQuotes();
    }

    function toggleProTip() {
        setShowProTips(!showProTips);
        StickySettings.setBool("showProTips", !showProTips);
    }

    return (
        <>
            <div>

                <div width="75%">
                    <center>
                        {showProTips ?
                            <div><div className='bold'>Pro Tip <Link className="smallest-text" onClick={() => toggleProTip()}>(Hide)</Link></div> {proTip}</div>
                            :
                            <div><Link className="smallest-text" onClick={() => toggleProTip()}>(Show Pro Tips)</Link></div>
                        }
                        <br></br>
                    </center>
                </div>

                <h3>Most Recent Trades <Link to="/trades/history">(See Trade Details)</Link></h3>
                <TradeInstructions planId={props.planId} accountValue={props.accountValue} />

                <hr></hr>
                <Accordion>
                    <AccordionSummary
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <div style={{ borderWidth: '2px', borderStyle: 'solid', borderColor: '#A0DDFD', width: '160px', padding: '5px', fontWeight: 'bold' }}>BONUS TRADES 👀</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        These trades are not part of this plan, but if you're looking for additional trades these can be taken and tracked from this location.
                        <BonusTrades mobileView={props.mobileView} userId={props.userId} accountValue={props.accountValue} />
                        <Link to="/trades/bonus">See All</Link>
                    </AccordionDetails>
                </Accordion>
                <br></br>

                <div className="sameline">
                    <h3>Current Positions <Link to="/trades/active">(See Active Trades)</Link></h3>
                </div>
                <PositionsTable planId={props.planId} accountValue={props.accountValue} positions={positions} previousPositions={previousPositions} mobileView={props.mobileView} />

                <center>
                    <Link className="bg-transparent" onClick={handleRefreshClicked}>Refresh</Link>
                </center>

                <PositionsChart planId={props.planId} positions={positions} />

                <hr></hr>
                <br></br>
                <h3>Performance <Link to={"/planRecap"}>(See YTD Summary)</Link></h3>
                <RecentReturns accountStartDate={props.accountStartDate} backtestResults={performanceResults} accountValue={props.accountValue} mobileView={props.mobileView}></RecentReturns>
                <center>
                    <Link to={"/updatePlan/changes"}>See latest plan changes</Link>
                </center>

                <br></br>
                <hr></hr>
                <br></br>
                <h3>Balance History <Link to={"/detailedPerformance"}>(See Detailed Performance)</Link></h3>
                <DailyBalanceChart defaultToYtd={true} startDate={props.accountStartDate} defaultToSyncValues={true} backtestResults={performanceResults} chartData={chartData} hideAllocationChart={true} hideChartSummary={true}></DailyBalanceChart>
                <br></br>
            </div >
        </>
    );
}

export default Overview; 