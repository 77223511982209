import { Link } from 'react-router-dom'
import { clearLocalStorage } from '../helpers/localStorageHelper'

export default function Logout() {

  // Delete session, then regardless of success/error, clear local storage
  fetch('/api/session', { method: 'DELETE' }).then(clearLocalStorage).catch(clearLocalStorage)
  
  // Make sure to use <a> tags to link back to the main app routes to ensure the state gets loaded fresh.
  return (
    <div className="empty-page" >
      <h3 className="login-title">You have been successfully logged out</h3>
      <p>Thank you for using Stockpiler! To continue, you can <a href='/'>return to the public plan</a>,
      or <Link to='/login'>log in again.</Link></p>
    </div>
  )
}