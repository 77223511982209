import React, { useState, useEffect } from 'react';
import Plan from './Plan'
import StockCorrelation from '../plan/StockCorrelation'
import Indicators from './Indicators'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';


function Positions(props) {

    let { activeTab } = useParams();

    const [tab, setTab] = useState(activeTab ? activeTab : 'summary');
    const [data, setData] = useState([]);

    useEffect(() => {
        (function () {

            fetch(`/api/positions?planId=` + props.planId)
                .then(response => response.json())
                .then(text => {

                    setData(text)
                });
        })();
    }, [props.planId]);

    function shouldAllow() {
        return true;
    }

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    let symbols = data.map(p => p.netSymbol);

    let allocations = data.map(p => { return { symbol: p.netSymbol, allocation: p.netPosition } })

    return (
        <div>
            {shouldAllow() ?
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={tab}
                            onChange={handleChangeTab}
                            textColor="secondary"
                            indicatorColor="secondary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab value="summary" label="Summary" />
                            <Tab value="indicators" label="Indicators" />
                            <Tab value="correlations" label="Correlations" />
                        </Tabs>
                    </Box>
                    <br></br>

                    {tab === "summary" ?
                        <>
                            <Plan mobileView={props.mobileView} planId={props.planId} accountValue={props.accountValue} />
                        </>
                        : null}
                    {tab === "correlations" ?
                        <>
                            <StockCorrelation symbols={symbols} days={20} showSpyCorrelation={true} allocations={allocations} />
                        </>
                        : null}
                    {tab === "indicators" ?
                        <>
                            <Indicators symbols={symbols} mobileView={props.mobileView} />
                        </>
                        : null}

                </>
                :
                <div class="empty-page" >Not allowed</div>
            }


        </div>
    );
}

export default Positions;