import React, { useState, useEffect } from 'react';
import tableHelpers from '../helpers/TableHelpers.js'
import Tooltip from '../components/Tooltip.js'
import RichTable from '../components/richTable/RichTable.jsx'

function AlertEvaluationResults(props) {

    const [performance, setPerformance] = useState({})

    useEffect(() => {
        (async function () {
            if (props.performance)
                setPerformance(props.performance);
        })();
    }, [props.alertId, props.performance]);

    function dynamicFormatter(cell, row) {
        if (row.format === 'int')
            return tableHelpers.intFormatter(cell, row);
        else if (row.format === 'decimal')
            return tableHelpers.decimalFormatter(cell, row);
        else if (row.format === 'configPopup')
            return configFormatter(cell, row);
        else if (row.format === 'status')
            return statusPopupFormatter(cell, row);
        else if (row.format === 'metrics')
            return metricsFormatter(cell, row);
        else
            return tableHelpers.percentFormatter(cell, row);
    }

    function rowClass(row) {
        console.log(row);
        if (row.title === "Win %" || row.title === "Avg P/L" || row.title === "Avg Max Trade DD" || row.title === "Net Profit") return "bold";
        else return "";
    }

    function getPerformanceRows() {
        console.log("performance", performance);
        return [
            { title: 'Config', value: 'Last', format: 'configPopup' },
            { title: 'Count', value: performance.tradeCount, format: 'int' },
            { title: 'Avg Duration', value: performance.avgDurationInBars, format: 'int' },
            { title: 'Win %', value: performance.winningPercentage },
            { title: 'Avg P/L', value: performance.avgProfit },
            { title: 'Net Profit', value: performance.netProfit },
            { title: 'Avg Win', value: performance.avgWin },
            { title: 'Avg Loss', value: performance.avgLoss },
            { title: 'Max Loss', value: performance.maxLoss },
            { title: 'Max Drawdown', value: performance.maxDrawdown },
            { title: 'Max Trade DD', value: performance.maxTradeDrawdown },
            { title: 'Avg Max Trade DD', value: performance.avgTradeMaxDrawdown },
            { title: 'Reward:Risk', value: performance.netProfit / performance.maxDrawdown, format: 'decimal' },
            { title: 'Current Bar', value: performance.currentBarStatus, format: 'status' },
            { title: 'All Metrics', value: performance, format: 'metrics' },
        ]
    }

    function configFormatter(cell, row) {
        return <Tooltip text={"Version " + cell} json={performance.config} />
    }

    function metricsFormatter(cell, row) {
        if (performance) {
            let metrics = JSON.parse(JSON.stringify(performance))
            metrics.trades = undefined;
            metrics.config = undefined;
            metrics.currentBarData = undefined;
            return <Tooltip text={"Show"} json={metrics} position="left center" />
        } else
            return "None";
    }

    function statusPopupFormatter(cell, row) {
        return <Tooltip text={cell} json={performance.currentBarData} position="left center" />
    }

    return (
        <RichTable data={getPerformanceRows()} mappers={[
            { title: 'Name', field: 'title' },
            { title: 'Value', field: 'value', formatter: dynamicFormatter },
        ]} className="table-striped table-hover table-condensed" rowClassName={rowClass} />
    );
}

export default AlertEvaluationResults;