import React, { useState } from 'react';
import ActiveTrades from './ActiveTrades';
import CompletedTrades from './CompletedTrades'
import AllocationChanges from './AllocationChanges'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import BonusTrades from './BonusTrades';


function Trades(props) {

    let { activeTab } = useParams();

    const [tab, setTab] = useState(activeTab ? activeTab : 'active');

    function shouldAllow() {
        return (props.role >= 0);
    }

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };


    return (
        <div>
            {shouldAllow() ?
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={tab}
                            onChange={handleChangeTab}
                            textColor="secondary"
                            indicatorColor="secondary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab value="active" label="Active" />
                            <Tab value="completed" label="Completed" />
                            <Tab value="history" label="Trade History" />
                            <Tab value="bonus" label="Bonus Trades" />
                        </Tabs>
                    </Box>
                    <br></br>

                    {tab === "active" ?
                        <>
                            <ActiveTrades planId={props.planId} mobileView={props.mobileView} onAlgoSelected={props.onAlgoSelected} accountValue={props.accountValue} />
                        </>
                        : null}
                    {tab === "completed" ?
                        <>
                            <CompletedTrades mobileView={props.mobileView} onAlgoSelected={props.onAlgoSelected} planId={props.planId} />
                        </>
                        : null}
                    {tab === "history" ?
                        <>
                            <AllocationChanges mobileView={props.mobileView} onAlgoSelected={props.onAlgoSelected} planId={props.planId} accountValue={props.accountValue} />
                        </>
                        : null}
                    {tab === "bonus" ?
                        <>
                            <BonusTrades showAll={true} userId={props.userId} accountValue={props.accountValue} mobileView={props.mobileView} />
                        </>
                        : null}

                </>
                :
                <div class="empty-page" >Not allowed</div>
            }


        </div>
    );
}

export default Trades;