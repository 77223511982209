import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

function renderErrorComponent(error) {
    return (
        <div>
            <h1>Login Failed</h1>
            <p>{error.message}</p>
            {
                error.details && <pre>{JSON.stringify(error.details, 2, 2)}</pre>
            }
        </div>
    )
}

export default function PostLogin({ onLogin }) {
    const [me, setMe] = useState(null)
    const [error, setError] = useState(null)
    const [searchParams] = useSearchParams()

    function renderLoggedInComponent(userContext) {

        setTimeout(() => {
            window.location.href = '/overview'
        }, 4000)

        return (
            <div>
                <h1>Welcome to Stockpiler!</h1>
                <p>You are logged in! Please continue to <a href="/overview">Overview</a></p>
                <p>Automatically redirecting in a few seconds...</p>
            </div>
        )
    }

    useEffect(() => {
        (async function () {
            const details = searchParams.get('details') ? `?details=elaborate` : ''
            const response = await fetch(`/api/loginOIDC${details}`)
            const body = await response.json()

            if (response.ok) {
                setMe(body)
                onLogin(body.userId, body.role, body.plans, body.agreement, body.cohort)
            } else {
                setError({ message: 'Unauthorized', details: body.details })
            }
        })();
    }, [onLogin, searchParams]);

    return (<>
        {!me && !error && <div>Verifying credentials, please wait...</div>}
        {me && renderLoggedInComponent(me)}
        {error && renderErrorComponent(error)}
    </>)
}