import React from 'react';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


function TwoStepConfirmationButton(props) {

    const [modalOpen, setModalOpen] = React.useState(false);

    function handleConfirm() {
        setModalOpen(true);
    }

    function handleSubmit() {
        if (props.onConfirmed)
            props.onConfirmed(props.id);
        setModalOpen(false);
    }

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    return (
        <>
            <button class="btn btn-danger" style={{ paddingTop: '0px', paddingBottom: '0px', backgroundColor: props.backgroundColor ? props.backgroundColor : undefined }} onClick={() => handleConfirm()}>{props.text}</button>

            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {props.text}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Are you sure?
                        <br></br>
                        <br></br>
                        <button class="btn btn-primary" onClick={() => handleSubmit()} type="button">Yes</button>
                        &nbsp;&nbsp;<button class="btn btn-secondary" onClick={() => setModalOpen(false)} type="button">Cancel</button>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}

export default TwoStepConfirmationButton;