import React, { useState, useEffect } from 'react';
import AccountSummaryCard from './AccountSummaryCard.js';
import RichTable from '../components/richTable/RichTable.jsx'
import tableHelpers from '../helpers/TableHelpers.js'
import backtestResultsHelpers from '../helpers/BacktestResultsHelpers.js'
import forceLogout from '../helpers/forceLogout.js'
import { Link } from 'react-router-dom'

function AccountsSummary(props) {

    const [accounts, setAccounts] = useState([]);
    const [performanceResults, setPerformanceResults] = useState({});
    const [previousPositions, setPreviousPositions] = useState({})

    useEffect(() => {

        async function loadPerformanceResults(planId) {
            const url = `/api/lastBacktestResults?planId=` + planId + "&liveTradesOnly=1";
            let text = await (await fetch(url)).json();
            return text;
        }

        async function loadPreviousPositions(planId) {
            const url = `/api/previousPositions?planId=` + planId
            let text = await (await fetch(url)).json();
            return text;
        }

        async function loadPlans(accounts) {
            let loadedPlans = {};
            let allPerformanceResults = {};
            let allPreviousPositions = {};

            for (let i = 0; i < accounts.length; i++) {
                const planId = accounts[i].planId;
                if (!loadedPlans[planId]) {
                    const performance = await loadPerformanceResults(planId);
                    const previousPositions = await loadPreviousPositions(planId);
                    allPerformanceResults[planId] = performance;
                    allPreviousPositions[planId] = previousPositions;
                    loadedPlans[planId] = true;
                }
            }
            setPerformanceResults(allPerformanceResults);
            setPreviousPositions(allPreviousPositions);
        }

        async function loadAccounts() {
            const response = await fetch(`/api/accounts?userId=${props.userId}`)
            if (response.ok) {
                const text = await response.json()
                setAccounts(text)
                loadPlans(text)
            } else {
                console.error(`Failed to load accounts for user ${props.userId} - HTTP ${response.status}`)
                forceLogout()
                return
            }
        }
        loadAccounts()

    }, [props.userId]);

    function getTodaysChange(positions) {
        var total = 0;
        if (positions) {
            for (let i = 0; i < positions.length; i++) {
                const pos = positions[i];
                total += pos.position * pos.change_percent / 100;
            }
        }
        return total;
    }

    function onAccountValueUpdated(accountId, value) {
        console.log("onAccountValueUpdated", accountId, value);
        let tempAccounts = accounts.map(a => a);
        let account = tempAccounts.find(a => a.idaccounts === accountId)
        if (account) {
            account.current_balance = Number(value);
            setAccounts(tempAccounts);

            if (props.onAccountsUpdated)
                props.onAccountsUpdated();
        }
    }

    function getTotals() {
        let totalAccountValue = 0;
        let weightedAvgProfit = 0;
        let weightedTodaysChange = 0;

        accounts.forEach(a => totalAccountValue += a.current_balance)

        accounts.forEach(a => {
            const performance = performanceResults[a.planId];
            const prevPositions = previousPositions[a.planId];
            const todaysChange = getTodaysChange(prevPositions);
            let netProfit = backtestResultsHelpers.getReturnSince(performance, new Date(a.create_date), false);
            let weight = a.current_balance / totalAccountValue;
            weightedAvgProfit += netProfit * weight;
            weightedTodaysChange += weight * todaysChange
        })

        return [{
            accountValue: totalAccountValue,
            netProfit: weightedAvgProfit,
            todaysChange: weightedTodaysChange,
        }]
    }

    return (
        <div>
            <h4>
                Totals
            </h4>
            <RichTable data={getTotals()} className="table-striped table-condensed" mappers={[
                { title: 'Total Value', field: 'accountValue', formatter: tableHelpers.moneyFormatterNoCents, width: '25%' },
                { title: `Total P/L`, field: 'netProfit', formatter: tableHelpers.percentFormatter, width: '25%' },
                { title: `Today's Total Change`, field: 'todaysChange', formatter: tableHelpers.percentFormatter, width: '25%' },
            ]} />

            <br></br>
            <h4>
                Accounts
            </h4>
            {accounts.map(account => {
                const performance = performanceResults[account.planId];
                const prevPositions = previousPositions[account.planId];
                const todaysChange = getTodaysChange(prevPositions);
                return (
                    <>
                        <AccountSummaryCard onAccountValueUpdated={onAccountValueUpdated} userId={props.userId} todaysChange={todaysChange} startDate={account.create_date} performanceResults={performance} accountId={account.idaccounts} accountName={account.name} planId={account.planId} planName={account.plan_name} accountValue={account.current_balance} />
                        <br></br>
                    </>
                )
            })}

            <hr></hr>

            <Link to="/addAccount">Manage Accounts</Link>
        </div >);
}

export default AccountsSummary;