import React, { useState, useEffect } from 'react';
import tableHelpers from '../helpers/TableHelpers.js'
import Tooltip from '../components/Tooltip.js'
import RichTable from '../components/richTable/RichTable.jsx'

function AlertPerformanceHistory(props) {

    const [performanceArray, setPerformanceArray] = useState([])

    useEffect(() => {
        (async function () {
            if (props.performanceArray)
                setPerformanceArray(props.performanceArray);
        })();
    }, [props.alertId, props.performanceArray]);

    function getPerformanceArray() {
        return performanceArray.map(performance => {
            return {
                id: performance.id,
                winningPercentage: performance.winningPercentage,
                avgProfit: performance.avgProfit,
                tradeCount: performance.tradeCount,
                avgDurationInBars: performance.avgDurationInBars,
                netProfit: performance.netProfit,
                avgWin: performance.avgWin,
                avgLoss: performance.avgLoss,
                maxLoss: performance.maxLoss,
                calculationTime: performance.calculationTime,
                config: performance.config,
                currentBarStatus: performance.currentBarStatus,
                currentBarData: performance.currentBarData,
                maxTradeDrawdown: performance.maxTradeDrawdown,
                avgTradeMaxDrawdown: performance.avgTradeMaxDrawdown,
                maxDrawdown: performance.maxDrawdown,
                rewardRiskRatio: performance.netProfit / performance.maxDrawdown,
                metrics: performance
            }
        })
    }

    function updateCharts(row) {
        let id = row.id;
        console.log("Updating chart to " + id);
        console.log("Searching " + performanceArray.length + " performance results");
        const perf = performanceArray.find(p => p.id === id);
        console.log("updateCharts", perf);
        //if (perf)
        //  setPerformance(perf);
    }

    function configPopupFormatter(cell, row) {
        return <Tooltip text={"Version " + cell} json={row.config} />
    }

    function metricsFormatter(cell, row) {
        let metrics = JSON.parse(JSON.stringify(row.metrics))
        metrics.trades = undefined;
        metrics.config = undefined;
        metrics.currentBarData = undefined;
        return <Tooltip text={"Metrics"} json={metrics} position="left center" />
    }

    function statusPopupFormatter(cell, row) {
        return <Tooltip text={cell} json={row.currentBarData} position="left center" />
    }

    const resultsMapper = [
        { title: 'Config', field: 'id', formatter: configPopupFormatter, sorters: true },
        { title: 'Count', field: 'tradeCount', formatter: tableHelpers.intFormatter, sorters: true },
        { title: 'Avg Duration', field: 'avgDurationInBars', formatter: tableHelpers.intFormatter, hidden: props.mobileView, sorters: true },
        { title: 'Win %', field: 'winningPercentage', formatter: tableHelpers.percentFormatter, sorters: true },
        { title: 'Avg P/L', field: 'avgProfit', formatter: tableHelpers.percentFormatter, sorters: true },
        { title: 'Net Profit', field: 'netProfit', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
        { title: 'Avg Win', field: 'avgWin', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
        { title: 'Avg Loss', field: 'avgLoss', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
        { title: 'Max Loss', field: 'maxLoss', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
        { title: 'Max Drawdown', field: 'maxDrawdown', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
        { title: 'Max Trade DD', field: 'maxTradeDrawdown', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
        { title: 'Avg Max Trade DD', field: 'avgTradeMaxDrawdown', formatter: tableHelpers.percentFormatter, sorters: true },
        { title: 'Reward:Risk', field: 'rewardRiskRatio', formatter: tableHelpers.decimalFormatter, hidden: props.mobileView, sorters: true },
        { title: 'Current Bar', field: 'currentBarStatus', formatter: statusPopupFormatter, sorters: true, hidden: props.mobileView },
        { title: 'All Metrics', field: 'allMetrics', formatter: metricsFormatter, hidden: props.mobileView },
    ]

    return (
        <div>
            <RichTable data={getPerformanceArray()} onRowClickHandler={updateCharts} mappers={resultsMapper} className="table-striped table-hover table-condensed" />
        </div >);
}

export default AlertPerformanceHistory;