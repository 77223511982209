import React, { useState } from 'react';
import Overview from './Overview';
import AccountsSummary from './accounts/AccountsSummary'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';


function Home(props) {

    let { activeTab } = useParams();

    const [tab, setTab] = useState(activeTab ? activeTab : 'overview');

    function shouldAllow() {
        return true;
    }

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };


    return (
        <div>
            {shouldAllow() ?
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={tab}
                            onChange={handleChangeTab}
                            textColor="secondary"
                            indicatorColor="secondary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab value="overview" label="Plan Overview" />
                            <Tab value="accounts" label="Accounts Summary" />
                        </Tabs>
                    </Box>
                    <br></br>

                    {tab === "overview" ?
                        <>
                            <Overview accountStartDate={props.accountStartDate} planId={props.planId} accountValue={props.accountValue} mobileView={props.mobileView} userId={props.userId} />
                        </>
                        : null}
                    {tab === "accounts" ?
                        <>
                            <AccountsSummary userId={props.userId} onAccountsUpdated={props.onAccountsUpdated} />
                        </>
                        : null}
                </>
                :
                <div class="empty-page" >Not allowed</div>
            }


        </div>
    );
}

export default Home;